@font-face {
  font-family: 'Pretendard';
  src: url('./Pretendard-Thin.woff2');
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./Pretendard-ExtraLight.woff2');
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./Pretendard-Light.woff2');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./Pretendard-Regular.woff2');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./Pretendard-Medium.woff2');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./Pretendard-SemiBold.woff2');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./Pretendard-Bold.woff2');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./Pretendard-ExtraBold.woff2');
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./Pretendard-Black.woff2');
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./Pretendard-Medium.woff2');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'NanumSquare Neo';
  src: url('./NanumSquareNeoOTF-Lt.otf');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'NanumSquare Neo';
  src: url('./NanumSquareNeoOTF-Rg.otf');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'NanumSquare Neo';
  src: url('./NanumSquareNeoOTF-Bd.otf');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'NanumSquare Neo';
  src: url('./NanumSquareNeoOTF-Eb.otf');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'NanumSquare Neo';
  src: url('./NanumSquareNeoOTF-Hv.otf');
  font-style: normal;
  font-weight: 900;
}
