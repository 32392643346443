.sort-modal-wrapper {
  width: 222px;
  height: 104px;
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px;
  position: absolute;
  top: 65px;
  right: 25px;
  gap: 20px;
  background-color: white;
  box-shadow: 10px 20px 20px 0px rgba(0, 0, 0, 0.16);
  z-index: 100;
}

.sort-modal-item {
  font-size: 14px;
  font-weight: 600;
  color: #bfbfbf;
}

.sort-modal-item-select {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}
