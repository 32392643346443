@import '../../../styles/texts';

.skip-button {
  border-radius: 20px;
  background-color: #f5f5f5;
  width: 355px;
  height: 66px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.skip-button-text {
  @include text-18-bold;
  color: black;
}
