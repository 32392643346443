.nothing-card-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #808080;
  font-size: 16px;
  font-family: 500;
}

.out-of-stock-img {
  width: 275px;
  height: 110px;
}
