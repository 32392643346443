@import '../texts';

.onboarding-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;
}

.onboarding-slide-button {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 20px;
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.onboarding-arrow {
  width: 40px;
  height: 40px;
}

.onboarding-arrow-left {
  transform: rotate(180deg);
}

.onboarding-slide-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 924px;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
}

.onboarding-slide-button-text {
  @include text-12-bold;
}

.onboarding-pagination {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 40px;
}

.onboarding-pagination-dot {
  width: 11px;
  height: 11px;
  border-radius: 12px;
  background-color: #dadada;
}

.onboarding-pagination-dot-current {
  background-color: var(--primary);
}

.onboarding-slider {
  width: 100%;
  height: 100%;
}

.onboarding-slide-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 34px;
}

.onboarding-slide-item-image {
  width: 900px;
}

.onboarding-slide-item-image-last {
  width: 300px;
}

.onboarding-slide-item-description {
  @include text-20-medium;
  text-align: center;
  display: block;
}

.onboarding-bi-image {
  width: 216px;
}

.empty-100 {
  width: 100px;
}
