@import '../../styles/texts';

.privacyPolicyModal-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 80px 0 20px 0;
  background-color: white;
  gap: 40px;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
}

.privacyPolicyModal-header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
  border-bottom: 1px solid #f5f5f5;
  background: white;
  width: 100%;
}

.privacyPolicyModal-header-title {
  @include text-30-extrabold;
}

.privacyPolicyModal-close-icon {
  width: 36px;
}

.privacyPolicyModal-contents {
  margin-top: 98px;
  display: flex;
  flex-direction: column;
  padding: 0 20px 72px 20px;
  padding-bottom: 72px;
  gap: 40px;
}

.privacyPolicyModal-text-16-regular {
  @include text-16-regular;
}

.privacyPolicyModal-text-16-semibold {
  @include text-16-semibold;
}

.privacyPolicyModal-text-16-bold {
  @include text-16-bold;
}

.pt-16 {
  padding-top: 16px;
}

.py-30 {
  padding: 30px 0;
}

.flex-flex-col-gap-16px {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
