@import '../../styles/texts';

.photocard-list-page-artist-item-container {
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
}

.photocard-list-page-artist-item-container-selected {
  background-color: var(--primary);
}

.photocard-list-page-artist-item-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 0.5px solid white;
}

.photocard-list-page-artist-item-text {
  @include text-14-semibold;
  text-align: center;
}

.photocard-list-page-artist-item-text-selected {
  color: #ffffff;
}
