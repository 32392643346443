@import '../../styles/texts';

.cart-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 10;
}

.cart-modal-container {
  width: 571px;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.cart-modal-header {
  width: 100%;
  padding: 40px 30px 20px 30px;
}

.cart-modal-header-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
}

.cart-modal-title-text {
  @include text-30-extrabold;
}

.cart-modal-close-icon {
  width: 36px;
  height: 36px;
  cursor: pointer;
}

.cart-modal-description-text {
  @include text-18-medium;
  color: #707070;
}

.cart-modal-info-box {
  width: 100%;
  padding: 18px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
}

.cart-modal-info-box-quantity-text {
  @include text-20-medium;
}

.cart-modal-info-box-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.cart-modal-info-box-delete-icon {
  width: 24px;
  height: 24px;
}

.cart-modal-info-box-delete-text {
  @include text-16-semibold;
}

.cart-modal-content-container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.cart-modal-item-container {
  width: 100%;
  padding: 24px 30px;
  height: 175px;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.cart-modal-item-image {
  width: 83px;
  // height: 176px;
  border-radius: 18px;
}

.cart-modal-item-text-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.cart-modal-item-info-text-area {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cart-modal-item-artist-text {
  @include text-16-bold;
}

.cart-modal-item-description-text {
  @include text-14-regular;
  color: #808080;
  width: 100%;
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 40px;
}

.cart-modal-item-price-text {
  @include text-20-bold;
}

.cart-modal-item-delete-area {
  width: 48px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.cart-modal-item-delete-icon {
  width: 24px;
  height: 24px;
}

.cart-modal-price-area {
  width: 100%;
  padding: 16px 30px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cart-modal-price-text {
  @include text-20-bold;
}

.cart-modal-button-area {
  padding: 18px 30px;
  width: 100%;
}

.cart-modal-button {
  width: 100%;
  height: 66px;
}

.cart-modal-information {
  width: 50%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  top: 300px;
  left: 90px;

  &__phrase {
    color: white;
    text-align: center;
    @include text-30-extrabold;
  }

  &__textbox {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__text_title {
    width: max-content;
    padding: 0 10px;
    margin: 0 auto;
    text-align: center;
    background-color: #fff2f2;
    @include text-20-bold;
  }

  &__text_contents {
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include text-20-medium;
  }

  &_text_if_1 {
    width: max-content;
    background-color: #ff6060;
    color: white;
    font-size: 20px;
    font-weight: 600;
    padding: 0 16.5px;
    border-radius: 4px;
    margin-bottom: 8px;
  }

  &_text_if_2 {
    width: max-content;
    background-color: #ff6060;
    color: white;
    font-size: 20px;
    font-weight: 600;
    padding: 0 10px;
    border-radius: 4px;
    margin-bottom: 8px;
  }
}
