@import '../../styles/texts';

.input-menu-wrap {
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dadada;
  border-radius: 10px;
  width: 320px;
  height: 52px;
  gap: 32px;
  cursor: pointer;
}

.input-menu-wrap-admin {
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dadada;
  border-radius: 10px;
  width: 248px;
  height: 52px;
  gap: 32px;
  cursor: pointer;
}

.input-menu {
  &::-ms-expand {
    display: none;
  }
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  @include text-14-semibold;
  appearance: none;

  &::focus {
    outline: none;
  }
  width: 100%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  border: 0 none;
  outline: 0 none;
  padding-left: 20px;
  position: relative;
  z-index: 3;
}

.option-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  @include text-14-semibold;
}

.input-menu-ul {
  width: 316.5px;
  min-height: 52px;
  max-height: 368px;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: white;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background: #a1a1a1;
    }
  }
}

//domain select wrapper
.input-menu-domain {
  width: 248px;
  height: 266px;
  position: absolute;
  top: 60px;
  left: 0px;
  border: 1px solid #dadada;
  overflow-y: scroll;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px #00000029;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background: #a1a1a1;
    }
  }
}

//nation select wrapper
.input-menu-wrapper {
  width: 320px;
  min-height: 52px;
  max-height: 368px;
  position: absolute;
  top: 97px;
  left: 0;
  border: 1.5px solid #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.16);
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 9;
  overflow: hidden;
}

.input-menu-option {
  background: white;
  color: black;
  padding: 16px;
  @include text-14-semibold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #c1c1c1;
  }
}

.input-menu-arrow-down {
  width: 15px;
  height: 15px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.select-nation-search-input-wrapper {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: 315px;
  height: 56px;
  gap: 8px;

  input {
    outline: none;

    &::placeholder {
      color: #dadada;
      font-size: 13px;
      font-weight: 500;
    }
  }

  .select-nation-input {
    width: 224px;
    font-size: 13px;
    font-weight: 600;
    outline: none;

    &::placeholder {
      color: #dadada;
      font-size: 13px;
      font-weight: 600;
    }
  }

  .select-nation-search-input-wrapper-left {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  border-bottom: 1px solid #f5f5f5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 101;
}

.no-search-result-text {
  font-size: 14px;
  color: #808080;
  font-weight: 600;
  padding: 16px;
  background-color: white;
  width: 315px;
}
