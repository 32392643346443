@import '../../../../styles/texts';

.type-section-wrapper {
  position: absolute;
  top: 56px;
  left: 0px;
  z-index: 10;
  background-color: white;
  width: 100%;
  height: 190px;
  box-shadow: 0px 7px 3px -2px#5e5e5e33;
}

.type-section-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  padding: 16px 37px 16px 20px;
  @include text-16-semibold;

  &__type {
    color: black;
  }

  &__reset {
    display: flex;
    gap: 4px;
    align-items: center;
    color: #808080;
  }
}

.type-section-list {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 16px 20px;
}

.type-section-item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 8px 20px;
  border-radius: 10px;
  @include text-16-semibold;
}

.item-selected {
  border: 1px solid black;
  color: white;
  background-color: black;
}

.item-not-selected {
  border: 1px solid #ededed;
  color: #bfbfbf;
  background-color: white;
}
