.store-modal-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: white;
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
}

.store-modal-main-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 96px 20px 0 20px;
}

.store-modal-main-left-wrapper {
  display: flex;
  flex-direction: column;
}

.store-buttons-wrapper {
  display: flex;
  gap: 16px;
}

.store-modal-cancel-button {
  background-color: #f5f5f5;
}

.store-modal-title {
  width: 100%;
  padding: 20px;
  font-size: 30px;
  font-weight: 800;

  border-bottom: #f5f5f5;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.store-modal-sub-title {
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 24px;
}

.store-modal-section-title {
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 8px;
}

.store-modal-address-desc {
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.store-modal-notice {
  margin-top: 24px;
  color: #808080;
  font-size: 14px;
  font-weight: 600;
}

.store-buttons-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 60px;
}

.store-modal-pick-up-table-wrapper {
  width: 700px;
  border: 1px solid #ededed;
  border-radius: 10px;
  overflow: hidden;
}

.pick-up-table {
  width: 100%;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  border-style: hidden;

  &_head {
    background-color: #fafafa;
    height: 50px;
    font-weight: 600;
  }

  &_first {
    width: 120px;
    font-weight: 600;
  }

  &_contents {
    height: 50px;
    font-weight: 500;
  }
}

table,
tr,
td {
  border: 1px solid #ededed;
  border-collapse: collapse;
}

.barcode-img {
  margin-top: 40px;
}