@import '../../styles/texts';

.terms-of-service-modal-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 80px 0 20px 0;
  background-color: white;
  gap: 40px;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
}

.terms-of-service-modal-header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
  border-bottom: 1px solid #f5f5f5;
  background: white;
  width: 100%;
}

.terms-of-service-modal-header-title {
  @include text-30-extrabold;
}

.terms-of-service-modal-close-icon {
  width: 36px;
}
