@import '../../../../styles/texts';

.filter-bar-count {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ff6060;
  color: white;
  @include text-13-medium;
}
