@import '../texts';

.photocard-list-page-container {
  width: 100%;
}

.photocard-gnb-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: white;

  z-index: 10;
}

.photocard-list-page-logo-area {
  width: 380px;
  height: 75px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  border-right: 1px solid #f5f5f5;
}

.photocard-list-page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
  height: 75px;
}

.photocard-list-page-header-title {
  @include text-30-extrabold;
}

.photocard-list-page-header-right-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.photocard-list-page-header-buttons-container {
  display: flex;
  height: 100%;
  position: relative;
}

.photocard-list-page-header-currency-box {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px 24px;
  border-left: 1px solid #f5f5f5;
}

.currency-select {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  gap: 16px;

  &__box {
    width: 230px;
  }

  &__placeholder {
    @include text-14-semibold;
    color: #bfbfbf;
  }

  &__value {
    @include text-14-semibold;
  }

  &__list {
    position: absolute;
    bottom: -100px;
    left: 0;
    width: 310px;
    padding: 16px 20px;
    background-color: white;
    border: 1px solid #f5f5f5;
    border-radius: 10px;
    box-shadow: 10px 20px 20px 0px rgba(0, 0, 0, 0.16);
    z-index: 99;
  }

  &__item {
    @include text-14-semibold;

    & + & {
      margin-top: 16px;
    }
  }
}

.photocard-list-page-header-button-item-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-left: 1px solid #f5f5f5;
  width: 116px;
  cursor: pointer;
}

.photocard-list-page-header-button-item-icon {
  width: 24px;
  height: 24px;
}

.photocard-list-page-header-button-item-text {
  @include text-14-semibold;
}

.photocard-list-page-artist-list-container {
  height: 100%;
  padding: 20px 20px 100px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 106px;
  gap: 17px;
  -ms-overflow-style: none;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0px;
  }
  border-right: 1px solid #f5f5f5;

  position: fixed;
  top: 75px;
}

.photocard-list-page-main {
  width: calc(100% - 307px);
  height: 100%;
  margin-top: 259px;
  margin-left: 307px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.photocard-list-page-artist-filter {
  width: 100%;
  padding: 18px 20px;
  -ms-overflow-style: none;
  overflow-x: auto;
  ::-webkit-scrollbar {
    width: 0px;
  }
}

.photocard-list-area {
  place-items: center;
  position: relative;
  background-color: #f9f9f9;
  width: 100%;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 352px;
  gap: 20px;
  padding: 30px 20px 150px 20px;

  .full-loading {
    position: absolute;
    top: 50% !important;
    left: 47% !important;
    z-index: 99;
  }

  .bottom-loading {
    position: relative;
    left: 150%;
  }
}

.photocard-list-bottom-area {
  height: 102px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 18px 15px;
  border-top: 1px solid #f5f5f5;

  width: calc(100% - 307px);
  position: fixed;
  right: 0;
  bottom: 0;
  background-color: white;
}

.empty-cart-modal-wrapper {
  width: 632px;
  height: 80px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: calc(50% - 40px);
  left: calc(50% - 316px);
  color: white;
  @include text-24-bold;
}

.filter-bar-wrapper {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 9;
}

.filter-bar-right-wrapper {
  display: flex;
  position: relative;
  @include text-16-semibold;

  > div {
    width: 160px;
    height: 56px;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #f5f5f5;
  }
}

.filter-bar-total-text {
  color: #808080;
  font-size: 16px;
}

.filter-bar-Newest {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.photocard-list-page-filter-section-wrapper {
  width: calc(100% - 307px);
  background-color: white;
  position: fixed;
  right: 0;
  top: 75px;

  z-index: 1;
}
