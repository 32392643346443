@import '../../styles/texts';

.locale-selection-container-simple {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f9f9f9;
  border: 0.5px solid #ededed;
  border-radius: 10px;
}

.locale-selection-item-simple {
  width: 68px;
  height: 58px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
}

.locale-selection-item-simple-selected {
  border: 1px solid black;
  background-color: #ffffff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
}

.locale-selection-item-simple-icon {
  width: 20px;
  height: 24px;
}

.local-selection-simple-title {
  font-size: 14px;
  font-weight: 500;
  margin: 0 16px;
}
