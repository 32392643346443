.spinner-wrap {
  position: relative;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 56px;
  width: 56px;
  box-sizing: border-box;
  background: conic-gradient(from 90deg at 50% 50%, rgba(39, 174, 96, 0) 0deg, rgba(31, 144, 255, 0) 0.04deg, #ff6060 360deg);
  border-radius: 56px;
  animation: 1s rotate infinite linear;
}
.spinner::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 40px;
  width: 40px;
  background: #fff;
  border-radius: 48px;
}
.spinner::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 8px;
  width: 8px;
  background: #ff6060;
  border-radius: 8px;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
