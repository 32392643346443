@import '../../styles/texts';

.company-modal-wrapper {
  width: 571px;
  border-radius: 20px;
  border: 1px solid #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  position: absolute;
  top: 80px;
  right: 290px;
  background-color: white;
  box-shadow: 10px 20px 20px 0px rgba(0, 0, 0, 0.16);
  z-index: 10;
}

.company-modal-header-text {
  @include text-30-extrabold;
}

.company-modal-header {
  display: flex;
  justify-content: space-between;
}
.company-modal-header-icon {
  width: 36px;
  height: 36px;
  cursor: pointer;
}

.company-modal-decs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  color: #808080;
  @include text-16-regular;
  margin-top: 24px;
}

.company-modal-span {
  display: flex;
  align-items: center;
  gap: 20px;
}
