*,
*::before,
*::after {
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0;
}

*:root {
  --primary: #fe6060;
  --primary-logo: #fe5353;
  --secondary: #151050;
  --default: #262626;
  --disabled: #6d7f92;
  --background-gray: #f4f4f4;
  --grayscale-100: #ededed;
  --grayscale-200: #c1c1c1;
  --grayscale-300: #9d9d9d;
}

html {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  font-family:
    'Pretendard',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
}

#root,
.App {
  width: 100%;
  height: 100%;
}

input {
  font-family: 'Pretendard';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
  color: initial;
}
