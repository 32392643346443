@import '../../../../styles/texts';

.member-search-wrapper {
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  padding: 16px 20px;
}

.member-search-input {
  @include text-16-semibold;
  width: 100%;
  outline: none;
  text-decoration: none;

  &::placeholder {
    font-weight: 600;
    font-size: 16px;
    color: #bfbfbf;
  }
}
