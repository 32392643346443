@import '../../styles/texts';

.filter-container {
  height: 80px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  border-bottom: 1px solid #f5f5f5;
}

.filter-item-container {
  height: 40px;
  padding: 8px 20px;
  border: 1px solid var(--grayscale-100);
  border-radius: 10px;
  cursor: pointer;
  width: auto !important;
}

.filter-item-container-selected {
  background-color: var(--primary);
  border: 0;
  width: auto !important;
}

.filter-item-text {
  @include text-16-semibold;
  color: #bfbfbf;
  word-break: keep-all;
  white-space: nowrap;
}

.filter-item-text-selected {
  color: #ffffff;
}

.weekly-faves-swiper-slide {
  width: auto !important;
}

.card-swiper-wrapper {
  padding-right: 16px !important;
  padding-left: 16px !important;
  margin-left: 0 !important;
}
