@import '../texts';

.photocard-detail-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.photocard-detail-container {
  width: 571px;
  height: 613px;
  border-radius: 20px;
  background-color: #ffffff;
}

.photocard-detail-top-area {
  padding: 30px 30px 24px 30px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.photocard-detail-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.photocard-detail-header-title-text {
  @include text-30-extrabold;
}

.photocard-detail-header-close-icon {
  width: 36px;
  height: 36px;
  cursor: pointer;
}

.photocard-detail-content-area {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
}

.photocard-detail-image {
  width: 204px;
  height: 327px;
  border-radius: 10px;
}

.photocard-detail-group-text {
  @include text-20-medium;
}

.photocard-detail-artist-text {
  @include text-24-bold;
}

.photocard-detail-name-text {
  @include text-14-regular;
  margin-top: 8px;
  margin-bottom: 16px;
  color: #808080;
  height: 72px;
}

.photocard-detail-tag-wrap {
  display: flex;
  flex-direction: row;
}

.photocard-detail-tag-container {
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 1px solid #000000;
}

.photocard-detail-tag-text {
  @include text-14-semibold;
}

.photocard-detail-price-area {
  padding: 16px 30px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.photocard-detail-price-text {
  @include text-20-bold;
}

.photocard-detail-button-area {
  padding: 16px 30px 20px 30px;
  width: 100%;
}

.photocard-detail-button {
  width: 100%;
  height: 66px;
}
