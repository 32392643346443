@import '../../styles/texts';

.order-error-page-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  z-index: 10;
}

.order-error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.order-error-page-logo-logo {
  width: 216px;
}

.order-error-character {
  width: 340px;
  height: 340px;
}

.order-error-talk-bubble {
  height: 61px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffe3e3;
  border-radius: 49px;
  padding: 0 32px;

  .triangle {
    position: absolute;
    bottom: -10px;
    z-index: -1;
    background-color: #ffe3e3;
    text-align: left;
    rotate: 180deg;
  }
  .triangle:before,
  .triangle:after {
    content: '';
    position: absolute;
    background-color: inherit;
  }
  .triangle,
  .triangle:before,
  .triangle:after {
    width: 8px;
    height: 8px;
    border-top-right-radius: 30%;
  }

  .triangle {
    transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
  }
  .triangle:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
  }
  .triangle:after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }
}

.order-error-talk-bubble-text {
  @include text-20-bold;
  z-index: 1;
}

.order-error-page-decs-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.order-error-page-error-code {
  font-size: 20px;
  font-weight: 700;
  color: black;
}

.order-error-page-decs {
  @include text-20-medium;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-page-btns-wrapper {
  display: flex;
  gap: 16px;
}

.order-page-left-btn {
  background: #f5f5f5 !important;
}
