@import '../texts';

.order-page-container {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  max-height: 100vh;
}

.order-page-left-area {
  position: relative;
  width: calc(100% - 571px);
  overflow-y: scroll;
  max-height: 100vh;
}

.order-page-fixed-area {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 571px);
  z-index: 10;
  background-color: white;
}

.order-page-header {
  padding: 20px;
  width: 100%;
}

.order-page-contents {
  display: flex;
  align-items: center;
  height: 56px;
  padding: 16px 20px;
  background-color: #f5f5f5;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #808080;
  img {
    margin-right: 8px;
  }
}

.order-page-header-text {
  @include text-30-extrabold;
  height: 36px;
}

.order-page-top-margin {
  margin-top: 132px;
}

.order-page-section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px 20px;
}

.order-page-section-title-area {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.order-page-section-subtitle-area {
  margin-top: 8px;
}

.order-page-section-title-text {
  @include text-24-bold;
}

.order-page-section-row {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
}

.order-page-section-item-full {
  flex: 1;
}

.input-ja > span {
  font-size: 14px !important;
}

.order-page-section-item-single {
  width: 533px;
}

.order-page-section-divider {
  width: 100%;
  height: 16px;
  background-color: #f5f5f5;
}

.order-page-section-checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.order-page-section-checkbox-label-text {
  @include text-16-semibold;
}

.order-page-section-checkbox-options-container {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.order-page-section-address-container {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 16px;
}

.order-page-section-address-bottom-area {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.order-page-section-address-caption-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.order-page-section-address-caption-text {
  @include text-13-medium;
  color: #808080;
}

.order-page-section-agreement-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.order-page-section-agreement-title {
  @include text-24-bold;
}

.order-page-title-section {
  display: flex;
  justify-content: space-between;
}

.order-page-section-agreement-title-desc {
  @include text-13-medium;
}

.order-page-section-agreement-subtitle {
  @include text-13-medium;
  margin-top: 8px;
}

.order-page-section-shipping-box {
  display: flex;
  align-items: center;
  gap: 24px;
}

.order-page-section-method {
  display: flex;
  flex-direction: column;
  gap: 24px;

  font-size: 14px;
  line-height: 20px;

  &__strong {
    font-weight: 600;
    display: inline-block;
    background-color: #fff2f2;
  }
  &__text {
    margin-top: 8px;
    font-weight: 500;
    &[data-margin='true'] {
      margin-top: 16px;
    }
  }

  &__desc {
    color: #808080;
  }
}

.order-page-section-shipping-method {
  display: flex;
  align-items: center;
  gap: 8px;
}

.order-page-section-agreement-box {
  width: 100%;
  height: 72px;
  border-radius: 10px;
  background-color: #f5f5f5;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.order-page-section-agreement-caption-refund {
  @include text-16-semibold;
  color: black;
  margin-bottom: 4px;
}

.order-page-section-agreement-caption-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.order-page-section-agreement-caption-text {
  @include text-13-medium;
  color: #808080;
}

.order-page-payment-method {
  display: flex;
  margin-top: 24px;
  gap: 25px;

  &__box {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    gap: 4px;
    width: 317px;
    height: 88px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    @include text-14-semibold;

    &[data-active='true'] {
      border: 1px solid #000000;
    }

    &[data-active='false'] {
      color: #808080;
      border: 1px solid #dadada;
    }
  }

  &__image {
    width: 24px;
    height: 24px;
  }
}

.order-page-terms-of-service {
  height: 24px;
  display: flex;
  gap: 6px;
}

.order-page-terms-of-service-text {
  @include text-16-semibold;
}

.order-page-arrow-right {
  width: 20px;
}

.order-page-cart {
  // width: calc(100% - 869px);
  width: 571px;
  height: 100vh;
  display: grid;
  grid-template-rows: 132px 1fr 100px 102px;
  border-left: 1px solid #f5f5f5;
}

.order-page-cart-header {
  width: 100%;
  height: 132px;
  padding: 40px 30px 20px 30px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #f5f5f5;
  gap: 8px;
}

.order-page-cart-title-text {
  @include text-30-extrabold;
}

.order-page-cart-text-wrap {
  display: flex;
  flex-direction: column;
}

.order-page-cart-text {
  @include text-18-medium;
}

.order-page-cart-items-area {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.order-page-cart-item-container {
  width: 100%;
  padding: 24px 30px;
  height: 175px;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.order-page-cart-item-image {
  width: 88px;
  border-radius: 10px;
}

.order-page-cart-item-text-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.order-page-cart-item-info-text-area {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.order-page-cart-item-artist-text {
  @include text-16-bold;
}

.order-page-cart-item-description-text {
  @include text-14-regular;
  color: #808080;
  width: 100%;
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 40px;
}

.order-page-cart-item-price-area {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.order-page-cart-item-price-text {
  @include text-20-bold;
}

.order-page-cart-item-quantity-text {
  @include text-16-semibold;
}

.order-page-cart-result-area {
  width: 100%;
  height: 100%;
  padding: 16px 30px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.order-page-cart-result-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.order-page-cart-result-item-text {
  @include text-14-semibold;
}

.order-page-cart-result-item-total-text {
  @include text-20-bold;
}

.order-page-cart-button-area {
  width: 100%;
  height: 100%;
  padding: 18px 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
}

.order-page-cart-back-button {
  background-color: #f5f5f5;
  width: 140px;
  color: #000000;
}

.order-page-cart-next-button {
  flex: 1;
}

.order-form-address-modal {
  position: absolute;
  width: 869px;
  top: 32px;
  z-index: 9;
}

.pick-up-notice-sub-title {
  font-size: 14px;
  font-weight: 600;
  background: #fff2f2;
  width: max-content;
  margin-bottom: 8px;
}

.pick-up-notice-sub-title-desc {
  font-size: 13px;
  font-weight: 600;
  margin: 16px 0;
}

.pick-up-notice-ul {
  li {
    font-size: 13px;
    list-style: none;
  }
}

.pick-up-notice-ul-gray {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 8px;

  li {
    font-size: 13px;
    list-style: none;
    color: #808080;
  }
}

.pick-up-notice-list-title {
  font-size: 13px;
  font-weight: 500;
}

.email-input {
  display: flex;
  align-items: flex-end;
  position: relative;
}

.email-input-label-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: calc(100vw - 611px);
  gap: 8px;
}

.select-pick-up-location {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
}

.select-pick-up-location-title {
  font-size: 16px;
  font-weight: 600;
}

.select-device-location {
  display: flex;
  gap: 40px;
}

.select-device-current-location {
  display: flex;
  align-items: center;
  gap: 4px;
}

.select-device-current-location-text {
  font-size: 16px;
  font-weight: 600;
  color: #bfbfbf;
}

.email-input-label {
  color: #808080;
  font-size: 13px;
  font-weight: 600;
}

.email-whelk-symbol {
  height: 52px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 600;
}

.email-domain {
  width: 248px;
  height: 52px;
}

.email-local-parts {
  width: 320px;
}

.input-domain-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.input-domain-wrap {
  margin-left: 8px;
}

.domain-select {
  margin-left: 8px;
}

.pick-up-table-wrapper {
  border: 1px solid #ededed;
  border-radius: 10px;
  overflow: hidden;
}

.pick-up-table {
  width: 100%;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  border-style: hidden;

  &_head {
    background-color: #fafafa;
    height: 50px;
    font-weight: 600;
  }

  &_first {
    width: 120px;
    font-weight: 600;
  }

  &_contents {
    height: 50px;
    font-weight: 500;
  }
}

table,
tr,
td {
  border: 1px solid #ededed;
  border-collapse: collapse;
}

.view-store-location-section {
  display: flex;
  align-items: center;
  color: #1daeff;
  font-size: 14px;
  font-weight: 600;
  gap: 4px;
  cursor: pointer;
}
