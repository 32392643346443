@import '../../styles/texts';

.photocard-list-item {
  width: 161px;
  height: 352px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.photocard-list-item-image {
  width: 100%;
  aspect-ratio: 161 / 249;
  border-radius: 20px;
}

.photocard-list-item-artist-text {
  @include text-14-bold;
  margin-top: 10px;
}

.photocard-list-item-description-text {
  @include text-14-regular;
  margin-top: 2px;
  color: #808080;
  width: 100%;
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 80px;
}

.photocard-list-item-price-text {
  @include text-16-bold;
  margin-top: 8px;
}
