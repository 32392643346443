@import '../texts';

.order-success-page-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding-top: 0px;
}

.order-success-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.order-success-page-title-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  width: 100%;
}

.order-success-page-title {
  font-size: 34px;
  font-weight: 800;
}

.order-success-page-desc {
  color: #808080;
  font-size: 18px;
  text-align: center;
}

.order-success-page-pickup-schedule-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 56px;
  margin-bottom: 32px;
}

.order-success-page-decs {
  @include text-20-medium;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-success-img-box {
  width: 436px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-success-character {
  width: 340px;
  height: 340px;
}

.order-success-number-box {
  width: 730px;
  border-radius: 10px;
  background: #f5f5f5;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  padding: 24px 40px;
  @include text-20-medium;
}

.order-success-bold-text {
  font-size: 30px;
  font-weight: 800;
  color: black;
}

.order-success-time-text {
  font-size: 30px;
  font-weight: 800;
  color: #ff6060;
}

.order-success-explanation-bold {
  @include text-16-regular;
  margin-top: 16px;
  font-weight: 700;
  color: black;
}

.order-success-text-box {
  display: flex;
  justify-content: space-between;
}

.order-success-email-section {
  display: flex;
  flex-direction: column;
}

.order-success-page-email-desc {
  font-size: 16px;
  color: black;
  font-weight: 500;
  text-align: end;
  margin-top: 24px;
  margin-bottom: 62px;
}

.stay-on-screen {
  background-color: #f5f5f5;
}

.order-success-page-buttons {
  display: flex;
  gap: 20px;
}
