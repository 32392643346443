.promotion-carousel-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  width: 110vw;
  gap: 3vw;
  background: #1e1b1b;
  position: fixed;
  left: -50px;
  filter: brightness(20%);
}
