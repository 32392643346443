@import '../../styles/texts';

.custom-calendar-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input-container {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 8px;
}

.input-label-text {
  @include text-16-semibold;
}

.input {
  @include text-14-semibold;
  height: 54px;
  border-radius: 10px;
  border: 1px solid #dadada;
  padding: 0 16px;
  outline: none;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox  */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.input:focus {
  border: 1px solid #000000;
}

.input::placeholder {
  @include text-14-semibold;
  color: #dadada;
}

.input-error {
  border: 1px solid #e52e2e;
}

.input-error-text {
  @include text-13-medium;
  color: #e52e2e;
  position: absolute;
  top: 55px;
}

.input-menu-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
