@import '../../styles/texts';

.pickup-banner-wrapper {
  width: 70%;
  display: flex;
  align-items: center;
  gap: 16px;
}

.pickup-banner-text-box {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;

  &_if {
    color: #ff6060;
    margin: 0;
  }
}

.pickup-banner-title {
  font-size: 16px;
  font-weight: 600;
  color: black;
}

.pickup-banner {
  height: 30px;
  overflow: hidden;
}

.pickup-banner-list {
  display: flex;
  flex-direction: column;
  gap: 8px;

  -webkit-animation-name: change;
  -webkit-animation-duration: 4.5s;
  -webkit-animation-iteration-count: infinite;
  animation-name: change;
  animation-duration: 4.5s;
  animation-iteration-count: infinite;

  &_item {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

@keyframes change {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, -30%, 0);
  }
  100% {
    transform: translate3d(0, -72%, 0);
  }
}
