.photocard-floating-btn-wrapper {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  box-shadow: 0px 10px 20px 0px #00000040;
  right: 16px;
  bottom: 120px;
  height: 60px;
  width: 60px;
  cursor: pointer;
}
