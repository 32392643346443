@import '../../styles/texts';

.locale-selection-container {
  display: flex;
  flex-direction: row;
  padding: 10px 10px;
  background-color: #f9f9f9;
  border: 1px solid #f5f5f5;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
}

.locale-selection-item {
  width: 120px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
}

.locale-selection-item-selected {
  background-color: #ffffff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
}

.locale-selection-item-icon {
  width: 20px;
  height: 24px;
}

.locale-selection-item-label {
  font-family: 'Pretendard';
  @include text-18-medium;
  margin-left: 4px;
  color: #bfbfbf;
}

.locale-selection-item-label-selected {
  color: #000000;
}
