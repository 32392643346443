// 키오스크 온보딩-1

@import './variables';
@import './colors';
@import './texts';
@import './fonts/index.scss';

div {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

div::-webkit-scrollbar {
  display: none;
}

.home-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-logo {
  margin-top: 119px;
  width: 216px;
  height: 26px;
}

.home-talk-bubble {
  height: 60px;
  margin-top: 46px;
  position: relative;
  padding: 20px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffe3e3;
  border-radius: 49px;

  .triangle {
    position: absolute;
    bottom: -10px;
    z-index: -1;
    background-color: #ffe3e3;
    text-align: left;
    rotate: 180deg;
  }
  .triangle:before,
  .triangle:after {
    content: '';
    position: absolute;
    background-color: inherit;
  }
  .triangle,
  .triangle:before,
  .triangle:after {
    width: 8px;
    height: 8px;
    border-top-right-radius: 30%;
  }

  .triangle {
    transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
  }
  .triangle:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
  }
  .triangle:after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }
}

.home-talk-bubble-text {
  @include text-20-bold;
  z-index: 1;
}

.home-character {
  width: 380px;
  height: 380px;
}

.home-description-text {
  @include text-20-medium;
  margin-top: 30px;
  text-align: center;
}

.home-onboarding-buttons-wrapper {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 20px;
}

// Wrapper Screen
.home-banner {
  width: 100%;
  height: 54px;
  background: #ff6060;
}

.home-scrolling-text {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  white-space: nowrap;
}

.home-text-box {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: animate_text 30s linear infinite;

  div {
    margin: 0 40px;
  }
}

.home-inner-box {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  font-family: 'NanumSquare Neo';
  font-size: 12px;
  font-weight: 700;
  line-height: normal;

  img {
    width: 20px;
    height: 22px;
    margin-right: 8px;
  }
}

.home-information {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10;

  .title {
    margin-top: 10px;
    color: white;
    font-size: 80px;
    line-height: 90px;
    text-align: center;
    font-weight: 800;
    letter-spacing: 2px;
  }

  > img {
    margin-top: 10px;
  }
}

.home-deco-box {
  position: relative;

  .home-deco-main {
    width: 1328px;
    height: 438px;
  }

  .home-deco {
    width: 996px;
    height: 436px;
    position: absolute;
    top: 30px;
    left: 180px;
    animation: blinker 3s linear infinite;
  }
}

.home-onboarding-button-buy-now {
  margin-top: 64px;

  .button-text {
    @include text-18-bold;
  }
}

.scroll-provider-wrapper {
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}
