@import '../../styles/texts';

.button {
  border-radius: 20px;
  background-color: var(--primary);
  width: 355px;
  height: 66px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button-text {
  @include text-18-bold;
  color: #ffffff;
}
