@mixin text-12-regular {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

@mixin text-12-bold {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
}

@mixin text-13-medium {
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
}

@mixin text-14-regular {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

@mixin text-14-semibold {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

@mixin text-14-bold {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

@mixin text-16-regular {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

@mixin text-16-semibold {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

@mixin text-16-bold {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

@mixin text-18-medium {
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
}

@mixin text-18-bold {
  font-size: 18px;
  line-height: 36px;
  font-weight: 700;
}

@mixin text-20-medium {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}

@mixin text-20-bold {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
}

@mixin text-24-bold {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

@mixin text-30-extrabold {
  font-size: 30px;
  line-height: 36px;
  font-weight: 800;
}
