@import '../../styles/texts';

.check-modal-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
}

.check-modal-container {
  width: 571px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 50px 50px 18px 50px;
  border-radius: 20px;
  background-color: #fff;
  text-align: center;
}

.check-modal-title {
  padding: 0 35px;
  font-size: 30px;
  font-weight: 800;
  color: black;
  margin-bottom: 24px;
}

.check-modal-desc {
  font-size: 20px;
  font-weight: 500;
  color: #808080;
}

.check-modal-order-data-box {
  width: 491px;
  height: 208px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  border-radius: 20px;
  box-shadow: -20px 20px 40px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid #ededed;
  margin-bottom: 30px;
}

.check-modal-order-data-list {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 700;
}

.check-modal-order-data-list > span:first-child {
  font-weight: 500;
}

.check-btn-box {
  display: flex;
  align-items: center;
  gap: 10px;
}

.check-btn {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 66px;
  border-radius: 20px;
  background: #f5f5f5;
  @include text-18-bold;

  &[data-primary='true'] {
    background: #ff6060;
    color: white;
  }
}

.check-modal-total {
  width: 491px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 58px;
}

.check-modal-total-value-text {
  font-size: 34px;
  font-weight: 800;
  color: #ff6060;
}
