.carousel {
  margin: auto 0;
  overflow: hidden;
  width: 100%;
  height: 140%;
  display: flex;
  flex-direction: column;
  position: absolute;
  transform: rotate(3deg);

  > div {
    display: flex;
    flex-direction: column;
  }
}

.scrollingAnimation {
  will-change: transform;
  animation: scrolling 30s linear infinite;
}

.scrollingReverseAnimation {
  will-change: transform;
  animation: scrolling2 30s linear infinite;
}

@keyframes scrolling {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes scrolling2 {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
