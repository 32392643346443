@import '../../styles/texts';

.back-modal-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.back-modal-container {
  min-width: 570px;
  padding: 50px 50px 18px 50px;
  border-radius: 20px;
  background: white;
  text-align: center;
}

.back-modal-title {
  @include text-30-extrabold;
}

.back-modal-contents {
  margin-top: 20px;
  margin-bottom: 42px;
  @include text-20-medium;
}

.back-btn-box {
  display: flex;
  gap: 18px;
}

.back-btn {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 66px;
  border-radius: 20px;
  background: #f5f5f5;
  @include text-18-bold;

  &[data-primary='true'] {
    background: #ff6060;
    color: white;
  }
}
