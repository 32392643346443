@import '../../styles/texts';

.checkbox-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.checkbox-image {
  width: 24px;
  height: 24px;
}

.checkbox-label-text {
  @include text-16-semibold;
}
